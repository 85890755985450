import React from 'react';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Heading } from '../Typography';
import { Button } from '../Button';
import { Container, Section } from '../Grid';
import BookNowController from '../BookNowController';
import GatsbyBackgroundImage from '../../shared/GatsbyBackgroundImage';
import useWindowBreakpoints from '../../hooks/useWindowBreakpoints';

const BannerWrapper = styled(Section)``;

const Banner = styled.div`
  border-radius: 24px;
  overflow: hidden;
`;

type IBookingBannerProps = {
  filename?: string;
  mobileFileName?: string;
  color?: 'primary' | 'white';
  heading: string;
  button_text?: string;
};

const StyledHeading = styled(Heading)`
  ${tw`mb-5 text-secondary`}

  max-width: 180px;
  color: ${props => (props.color === 'white' ? 'white' : '#224B45')};

  @media ${props => props.theme.screens.md} {
    ${tw`mb-5`}
    max-width: 340px;
  }

  @media ${props => props.theme.screens.lg} {
    ${tw`mb-10`}
    max-width: 536px;
  }
`;

const StyledButton = styled(Button)`
  height: 36px;
  padding: 10px 25px;
  box-shadow: 9px 9px 31px -10px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  color: ${props => (props.color === 'white' ? props.theme.colors.accent : '')};

  @media ${props => props.theme.screens.md} {
    padding: 16px 54px;
    height: 60px;
  }
`;

const TextWrapper = styled.div`
  padding: 54px 0px 16px 32px;

  @media ${props => props.theme.screens.md} {
    padding: 64px 0px 71px 35px;
  }

  @media ${props => props.theme.screens.lg} {
    padding: 126px 0px 91px 91px;
  }
`;

const BookingBanner: React.FC<IBookingBannerProps> = ({
  filename = 'component-book-background.jpg',
  mobileFileName = '',
  color = 'primary',
  heading = 'Default text for book component heading',
  button_text = 'Book now',
}) => {
  const { t } = useTranslation();
  const { isReallySm } = useWindowBreakpoints();
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(filter: {extension: {in: ["jpg", "png"]}}) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 90
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  const image = images.edges.find((n: any) => {
    return n.node.relativePath.includes(filename);
  });

  let mobileImage = null;
  if (mobileFileName) {
    mobileImage = images.edges.find((n: any) => {
      return n.node.relativePath.includes(mobileFileName);
    });
  }

  return (
    <BannerWrapper className="mt-20 sm:mt-0 lg:mt-28">
      <Container>
        <Banner>
          <GatsbyBackgroundImage
            image={isReallySm && mobileFileName ? mobileImage.node : image.node}
            alt="Booking banner"
            imgStyle={{ zIndex: -101 }}
            wrapperStyle={{
              marginTop: '0px',
              height: 500,
              pointerEvents: 'none',
            }}
          >
            <TextWrapper>
              <StyledHeading color={color}>{t(heading)}</StyledHeading>
              <BookNowController>
                <StyledButton
                  color={color}
                  size="medium"
                  style={{ pointerEvents: 'all' }}
                >
                  {t('book_now')}
                </StyledButton>
              </BookNowController>
            </TextWrapper>
          </GatsbyBackgroundImage>
        </Banner>
      </Container>
    </BannerWrapper>
  );
};

export default BookingBanner;
