import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../../Grid';
import BookNowController from '../../BookNowController';
import {GatsbyBackgroundPageHero} from '../../../shared/GatsbyBackgroundImage';
import {Button} from '../../Button';
import useWindowBreakpoints from '../../../hooks/useWindowBreakpoints';
import {getImage} from 'gatsby-plugin-image';
import TabletHero from './TabletHero';
import useWindowSize from '../../../hooks/useWindowSize';
import {useI18next} from 'gatsby-plugin-react-i18next';

const CustomContainer = styled(Container)`
  background-color: ${props => props.bg};
`;

const Content = styled.div`
  ${tw`text-secondary text-center lg:text-left`}

  max-width: 700px;
  margin: 0 auto;

  @media ${props => props.theme.screens.md} {
    margin-top: 0;
  }

  @media ${props => props.theme.screens.lg} {
    margin: 0;
    margin-top: 50px;
  }
`;

const Heading = styled.h1`
  padding-top: 0px;

  @media ${props => props.theme.screens.md} {
    padding-top: 10px;
    text-align: left;
    padding-bottom: 32px;
    font-size: 45px;
    line-height: 56px;
  }
  @media ${props => props.theme.screens.lg} {
    font-size: 50px;
    line-height: 64px;
    padding-top: 100px;
    width: ${props => props.width};
  }

  font-size: 30px;
  line-height: 42px;
`;

const Text = styled.p`
  ${tw`mt-4`}
  margin-bottom: 46px;

  a {
    color: #039259;
    text-decoration: underline;
  }
  color: #224b45;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
    text-align: left;
  }
  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full lg:w-max`}
  height: 68px !important;
  font-size: 18px;
  line-height: 139.02%;
  letter-spacing: 0.01em;
`;

const SmallImageContainer = styled.div`
  ${tw`absolute sm:transform sm:-translate-y-1/2 md:left-3/4 md:transform md:-translate-y-1/3 lg:left-1/2  lg:transform lg:translate-x-1/3 lg:translate-y-1/2`};
  bottom: -200px;
  height: 230px;
  width: 350px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${props => props.theme.screens.md} {
    ${tw`absolute bottom-0 sm:transform  md:transform  lg:transform`};
    width: 381px;
    height: 253px;
    left: 40%;
    margin-right: 40px;
    bottom: -100px;
  }

  @media ${props => props.theme.screens.lg} {
    position: absolute;
    width: 463px;
    height: 300px;
    right: 80px;
    bottom: -100px;
    margin-right: 0;
    left: unset;
  }
`;

const Wrapper = styled(Section)``;

const PageHero = () => {
  const {t} = useTranslation();
  const {language} = useI18next();
  const {isReallySm, isMD} = useWindowBreakpoints();
  const {heading, text} = data.office_cleaning.hero;

  const {background, smallBackground, backgroundMobile, backgroundTablet} =
    useStaticQuery(graphql`
      query {
        background: file(relativePath: {eq: "office-cleaning-hero.png"}) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
        backgroundMobile: file(
          relativePath: {eq: "office-cleaning-hero-mobile.png"}
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
        backgroundTablet: file(
          relativePath: {eq: "office-cleaning-hero-tablet.png"}
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
        smallBackground: file(
          relativePath: {eq: "office-cleaning-small-hero.png"}
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              quality: 90
            )
          }
        }
      }
    `);
  const size = useWindowSize();
  const CUSTOM_BREAKPOINT = 975;
  const isTablet = size?.width <= CUSTOM_BREAKPOINT && size?.width > 480;
  const image = isReallySm ? backgroundMobile : background;
  const smallImg =
    smallBackground.childImageSharp.gatsbyImageData.images.fallback.src;

  if (isTablet) {
    return (
      <Wrapper>
        <Container>
          <div className="relative">
            <TabletHero img={backgroundTablet}>
              <Content>
                <Heading width={language === 'en' ? '100%' : '80%'}>
                  {t(heading)}
                </Heading>
                <Text dangerouslySetInnerHTML={{__html: t(text)}} />
                <div style={{width: 220, height: 68}}>
                  <BookNowController>
                    <StyledButton>{t('office_cleaning_book')}</StyledButton>
                  </BookNowController>
                </div>
              </Content>
            </TabletHero>
            <SmallImageContainer>
              <img src={smallImg} alt="small-hero" />
            </SmallImageContainer>
          </div>
        </Container>
      </Wrapper>
    );
  }

  let objectPosition = isReallySm
    ? {objectPosition: 'center 90%'}
    : {objectPosition: 'unset'};
  const ContentComponent = () => (
    <CustomContainer bg={isReallySm ? {bg: 'transparent'} : {bg: 'white'}}>
      <Content>
        <Heading width={language === 'en' ? '100%' : '80%'}>
          {t(heading)}
        </Heading>
        <Text dangerouslySetInnerHTML={{__html: t(text)}} />
        <BookNowController>
          <StyledButton>{t('office_cleaning_book')}</StyledButton>
        </BookNowController>
      </Content>
    </CustomContainer>
  );
  return (
    <Wrapper>
      <Container>
        <div className="relative">
          <GatsbyBackgroundPageHero
            image={getImage(image)}
            wrapperStyle={isMD ? {bg: 'transparent'} : {bg: 'white'}}
            imgStyle={objectPosition}
            miniImageProps={{
              height: '400px',
            }}
          >
            <ContentComponent />
          </GatsbyBackgroundPageHero>
          <SmallImageContainer>
            <img src={smallImg} alt="small-hero" />
          </SmallImageContainer>
        </div>
      </Container>
    </Wrapper>
  );
};

export default PageHero;
